
<!-----------------------------------
  論文リストビューア
  論文リストの一覧表示の実施を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div class="py-1">
    <div class="d-flex flex-column paper-size" ref="TableClient">
      <perfect-scrollbar class="flex-grow-1" ref="TextContentScroll">
        <div ref="HtmlContent">
          <TanstackTableVue v-if="0 < this.columns.length" class="paper-table" :itemSource="paperList" :columns="columns"
            v-slot="{ cell }" tag="PaperListViewer">
            <!-- urlの場合はリンク表示 -->
            <a v-if="cell.column.id == 'url'" v-bind:href="cell.row.original[cell.column.columnDef.accessorKey]"
              target="_blank" rel="noopener noreferrer" aria-label="paper link"
              @click.left="onLinkClick(cell.row.original[cell.column.columnDef.accessorKey])"
              @click.middle="onLinkClick(cell.row.original[cell.column.columnDef.accessorKey])">
              <span v-html="searchHighlight(cell.row.original[cell.column.columnDef.accessorKey], searchKey)"></span>
            </a>
            <a v-else-if="hasAbstract(cell, 'title')" href="#" data-bs-toggle="modal" data-bs-target="#PaperAbstract"
              @click="onTitleClick(cell.row.original['url'], cell.row.original['abstract'])">
              <span v-html="searchHighlight(cell.row.original[cell.column.id], searchKey)"></span>
            </a>
            <span v-else v-html="searchHighlight(cell.row.original[cell.column.id], searchKey)"></span>
          </TanstackTableVue>
        </div>
      </perfect-scrollbar>
    </div>
  </div>

  <!-- 患者データポップアップ -->
  <teleport to="body">
    <div id="PaperAbstract" class="modal">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="ModalClose"></button>
          </div>
          <div class="modal-body">
            <a :href="modalUrl" target="_blank" rel="noopener noreferrer" @click.left="onLinkClick(modalUrl)"
              @click.middle="onLinkClick(modalUrl)">
              <span v-html="searchHighlight(modalUrl, searchKey)"></span>
            </a>
            <div class="fs-4 mt-1">Abstract</div>
            <hr class="my-1" />
            <p class="modal-abstract" v-html="searchHighlight(modalAbstract, searchKey)"></p>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import TanstackTableVue from "@/components/Utility/TanstackTable.vue";
import { createColumnHelper } from "@tanstack/vue-table";
import ContentHelper from "@/helpers/ContentHelper";
import LogHelper from "@/helpers/LogHelper";
import customAxios from "@/helpers/AxiosHelper";

export default defineComponent({
  name: "PaperListViewer",

  components: {
    TanstackTableVue,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    contentId: String,
    listURL: String,
    searchKey: String,
  },

  setup() {

    // テキストコンテンツ用のリサイズ検知
    /** @type {import("vue").Ref<HTMLElement>} */
    const HtmlContent = ref();
    /** @type {import("vue").Ref<import("perfect-scrollbar").default>} */
    const TextContentScroll = ref();
    /** @type {ResizeObserver} */
    let contentObserver;
    onMounted(() => {
      contentObserver = new ResizeObserver(() => {
        TextContentScroll.value?.update();
      });
      contentObserver.observe(HtmlContent.value);
    });
    onBeforeUnmount(() => {
      contentObserver?.disconnect();
    });

    /** @type {(cell:import("@tanstack/vue-table").Cell, id:string)=>boolean} */
    const hasAbstract = (cell, id) => {
      return cell.column.id == id
        && cell.row.original['abstract'] != null
        && process.env.VUE_APP_ENV == "proto"; // 開発環境のみ表示する
    };

    return {
      HtmlContent,
      TextContentScroll,
      hasAbstract,
    };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      columns: [],
      paperList: [],

      modalUrl: "",
      modalAbstract: "",
    };
  },
  //*****************************
  // 初期化処理
  //*****************************
  created: function () {
    this.fetchListUrl(this.listURL);
  },
  //*****************************
  // プロパティ監視処理
  //*****************************
  watch: {
    // listファイル
    listURL: {
      handler: function () {
        this.fetchListUrl(this.listURL);
      },
    },
  },
  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * Paper.json取得処理
     * @param {string} url jsonファイルのURL
     */
    async fetchListUrl(url) {
      try {
        const res = await customAxios.get(encodeURI(url));
        /**
         * @type {{
         *  header: [{
         *    text: string;
         *    value: string;
         *    width: string;
         *  }];
         *  data: [{[key:string]:string}]
         * }}
         */
        const json = res.data;

        /** @type {HTMLElement} */
        const tableClient = this.$refs["TableClient"];
        const width = tableClient.clientWidth - (9.6 * 2);

        // カラム定義
        /** @type {import("@tanstack/vue-table").ColumnHelper<ListFinderUser>} */
        const columnHelper = createColumnHelper();
        /** @type {import("@tanstack/vue-table").ColumnDef<ListFinderUser,any>[]} */
        const columns = [];
        for (const header of json.header) {
          const per = Number(header.width.slice(0, -1)) / 100;
          columns.push(
            columnHelper.accessor(header.value, {
              header: header.text,
              cell: (info) => info.getValue(),
              size: Math.floor(width * per),
            })
          );
        }
        this.columns = columns;
        this.paperList = json.data.filter((v) => v["url"] != "");
      } catch (error) {
        this.columns = [];
        this.paperList = [];
        console.error(error);
      }
    },

    /**
     * タイトルクリック時のモーダル表示
     * @param {string} url 
     * @param {string} abstract 
     */
    onTitleClick(url, abstract) {
      this.modalUrl = url;
      this.modalAbstract = abstract;
    },

    /**
     * 遷移ログの保存
     * @param {string} link 
     */
    onLinkClick(link) {
      LogHelper.postLog(LogHelper.log.transition, link, this.contentId, "");
    },

    //================================
    // 検索ワード強調表示
    //================================
    searchHighlight: ContentHelper.searchHighlight,
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
.paper-size {
  /* 縦幅 - テーブル余白 */
  height: calc(var(--ddr-content-height) - 0.5rem);
}

.pc .paper-size {
  width: calc(100vw - var(--ddr-sidemenu-width));
}

.mobile .paper-size {
  width: 100vw;
}

.paper-size>div {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.paper-table {
  font-size: 12px;
  table-layout: fixed;
}

/* ダイアログ幅 */
.modal-dialog {
  max-width: min(800px, calc(100% - 1rem));
}

/* ダイアログ高さ */
.modal-body {
  height: calc(100vh - (70px * 2) - 49px);
}

/* Abstract */
.modal-abstract {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
