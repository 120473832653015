<!-----------------------------------
    メニューツリー
    サイドバー用のメニューツリーを実現する。
    本コンポーネントは再帰にてツリーを表示する
----------------------------------->

<!-----------------------------------
    テンプレート
----------------------------------->
<template>
  <ul class="list-group menu-list-group">
    <template v-for="(value, key) in state" :key="key">
      <li v-if="value.child == null" class="list-group-item" @click.stop="selectMethod(value.id)"
        @click.right.prevent="onRightclick(value, $event)">
        <div class="d-flex align-items-center menu-item" :class="{ 'active': isActive(value) }"
          :style="`padding-left:${layer * 24}px`">
          <div class="menu-icon"></div>
          <div v-if="value.type == 'item-TextBook'" class="menu-icon">
            <img src="img/menu/text-book.png" />
          </div>
          <div v-else-if="value.type == 'item-PatientList'" class="menu-icon">
            <img src="img/menu/patient-list.png" />
          </div>
          <div v-else-if="value.type == 'item-PatientData'" class="menu-icon">
            <img src="img/menu/patient-data.png" />
          </div>
          <div v-else-if="value.type == 'item-PaperList'" class="menu-icon">
            <img src="img/menu/paper-list.png" />
          </div>
          <div v-else-if="value.type == 'item-Notice'" class="menu-icon">
            <img src="img/menu/notice.png" />
          </div>
          <div v-html="$options.filters.searchHighlight(value.name, searchKey)"></div>
          <!-- お知らせの特殊表示 -->
          <div v-if="value.name == 'お知らせ' && 0 < options.unreadNotice" class="notice">
            （未読{{ options.unreadNotice }}件）
          </div>
        </div>
      </li>
      <li v-else-if="showFolder(value)" class="list-group-item" @click.stop="treeClick(key)">
        <div class="d-flex align-items-center menu-item" :class="{ 'menu-folder': layer == 0 }"
          :style="`padding-left:${layer * 1.5}rem`">
          <template v-if="value.Open">
            <div class="menu-icon arrow"><img src="img/menu/arrow-open.png" /></div>
          </template>
          <template v-else>
            <div class="menu-icon arrow"><img src="img/menu/arrow-close.png" /></div>
          </template>

          <template v-if="value.name == 'ブックマーク'">
            <div class="menu-icon"><img src="img/menu/bookmark.png" /></div>
          </template>
          <template v-else-if="value.Open">
            <div class="menu-icon"><img src="img/menu/folder-open.png" /></div>
          </template>
          <template v-else>
            <div class="menu-icon"><img src="img/menu/folder-close.png" /></div>
          </template>
          <div v-html="$options.filters.searchHighlight(value.name, searchKey)"></div>
        </div>
        <MenuTree v-if="value.Open" :content="value.child" :select="select" :activeSelect="activeSelect"
          :searchKey="searchKey" :searchData="searchData" :options="options" :showFolder="showFolder" :layer="layer + 1"
          @TreeClickProp="TreeClickProp" @selectMethod="selectMethod" @onRightclick="onRightclick" />
      </li>
    </template>
  </ul>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, toRefs } from "vue";
import ContentHelper from "@/helpers/ContentHelper";

/**
 * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
 */

export default defineComponent({
  name: "MenuTree",

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    /** @type {StringConstructor} */
    searchData: null,
    /** @type {StringConstructor} */
    select: null,
    /** @type {StringConstructor} */
    activeSelect: null,
    /** @type {StringConstructor} */
    searchKey: null,
    // 表示ツリー
    content: {
      /** @type {import("vue").PropType<ContentData[]>} */
      type: Array,
      default() {
        return [];
      },
    },

    options: {
      /**
       * @type {import("vue").PropType<{
       *  archiveDisplay: boolean;
       *  unreadNotice: number;
       * }>}
       */
      type: Object,
    },
    /** @type {FunctionConstructor} */
    showFolder: null,

    layer: {
      type: Number,
      default() { return 0; }
    },
  },
  //*****************************
  // 初期化処理
  //*****************************
  setup(props) {
    const { content } = toRefs(props);
    return {
      state: content
    };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() { return {}; },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * 検索ヒットチェック
     * @param {ContentData} value 対象コンテンツ
     * @returns IDが検索結果と一致していればTrue
     */
    isSearchHit(value) {
      if (!this.searchData) {
        return false;
      }

      let isHit = this.searchData.includes(value.id + "");
      if (isHit) {
        this.$emit("childHit", value);
      }

      return isHit;
    },
    /**
     * 検索ヒットチェック（子検索）
     * @param {ContentData} value 対象コンテンツ
     * @returns 子に検索ヒット対象がいればTrue
     */
    isSearchHitChild(value) {
      if (this.isSearchHit(value)) {
        return true;
      }

      // 全要素に対して処理を行う
      for (let i = 0; i < Object.keys(value.child).length; i++) {
        if (this.isSearchHit(value.child[i])) {
          return true;
        }

        // 子要素が存在する＝フォルダとして処理する
        if (value.child[i].child) {
          if (this.isSearchHitChild(value.child[i])) {
            return true;
          }
        }
      }

      return false;
    },

    /**
     * コンテンツ選択処理
     * @param {string} value 選択コンテンツ
     */
    selectMethod(value) {
      this.$emit("selectMethod", value);
    },
    /**
     * 表示切り替え処理
     * @param {string} index クリックしたコンテンツのID
     */
    treeClick(index) {
      this.state[index].Open = !this.state[index].Open;
      this.TreeClickProp();
    },
    /** 表示切り替えの上位伝達 */
    TreeClickProp() {
      this.$emit("TreeClickProp");
    },
    /**
     * 選択状態判定
     * @param {ContentData} value 対象コンテンツ
     * @returns 選択コンテンツと一致していればTrue
     */
    isActive(value) {
      if (this.select) {
        return this.select == value.id || this.activeSelect == value.id;
      } else {
        return false;
      }
    },
    /**
     * 右クリック検知
     * @param {string} value 対象コンテンツ
     * @param {MouseEvent} e イベント情報
     */
    onRightclick(value, e) {
      this.$emit("onRightclick", value, e);
    },
  },

  //*****************************
  // HTML表示フィルター処理
  //*****************************
  filters: {
    // 検索ワード強調表示
    searchHighlight: ContentHelper.searchHighlight,
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
ul.list-group.menu-list-group {
  margin: 0px;
}

.list-group.menu-list-group li.list-group-item {
  margin: 0px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: rgb(191, 191, 191);
  cursor: pointer;
}

.list-group .list-group-item .menu-item {
  width: 100%;
  height: calc(2rem - 1px);
}

.menu-item.active {
  background-color: rgb(0, 32, 96);
  color: rgb(255, 255, 255);
}

.menu-item>.notice {
  color: rgb(255, 228, 133);
}

.menu-folder {
  background-color: rgb(89, 89, 89);
}

.menu-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 1px 1px 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon.arrow {
  padding: 6px 6px 6px 6px;
}

.menu-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
