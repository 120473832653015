<!-----------------------------------
  患者データコンテンツビューア
  患者データのコンテンツ表示の実施を行う
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <div :class="{ PatientDataViewer: !isModal, ModalPatientDataViewer: isModal }">
    <teleport to=".video-js.patient-main-video" v-if="isVideoMounted">
      <!-- 患者データのVideoJS内にグラフを注入する -->
      <div v-show="showKokyuVideoGraph" class="position-absolute w-100 kokyu-video-graph"
        :class="{ 'on-cursor': isChartHover }">
        <Line v-if="GraphSelect == 'DM'" class="w-100 h-100" :data="graphVideoDMData" :options="graphVideoDMOptions" />
        <Line v-if="GraphSelect == 'LA'" class="w-100 h-100" :data="graphVideoLAData" :options="graphVideoLAOptions" />
      </div>
    </teleport>
    <perfect-scrollbar>
      <div class="row g-0" v-bind:class="{ 'row-cols-1': isShrink, 'row-cols-2': !isShrink }">
        <!--動画-->
        <div class="col bg-black">
          <!-- 縮小表示時は、動画領域を動的に変更 -->
          <div class="d-flex flex-column" v-bind:class="{ ShrinkMovieContent: isShrink, MovieContent: !isShrink, }">
            <!-- 動画領域 -->
            <div class="overflow-hidden text-center">
              <div class="position-relative h-100">
                <VideoPlayer class="w-100 h-100 patient-main-video" :src="dispMovie" ref="VideoPlayerDom" />
              </div>
            </div>

            <!-- ボタン領域 -->
            <div v-if="!isShrink" class="d-flex justify-content-center flex-wrap p-2 gap-2">
              <!-- 通常表示時 -->
              <button v-for="value in dispContent.datas" :key="value" class="btn btn-primary ContentButton"
                v-on:click="setSelect(value)" v-bind:class="{ active: isActive(value) }"
                v-bind:disabled="!dispButtonState[value]">{{ value }}</button>
              <div class="d-flex align-items-center volume-check">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="hasVolume" id="hasVolumeCheck"
                    @change="setVolume(hasVolume)">
                  <label class="form-check-label" for="hasVolumeCheck">音声指示</label>
                </div>
              </div>
            </div>
            <div v-else class="dropdown p-2 text-center d-flex justify-content-center">
              <!-- 縮小表示時 -->
              <button class="btn btn-primary dropdown-toggle ContentButton" type="button" id="contentDropdownMenu"
                data-bs-toggle="dropdown" aria-expanded="false">{{ Select }}</button>
              <ul class="dropdown-menu" aria-labelledby="contentDropdownMenu">
                <li v-for="value in dispContent.datas" :key="value">
                  <!-- 有効なボタンのみリスト表示 -->
                  <button v-if="dispButtonState[value]" class="dropdown-item ContentButton w-100"
                    v-on:click="setSelect(value)" v-bind:class="{ active: isActive(value) }">{{ value }}</button>
                </li>
              </ul>
              <div class="ms-3 d-flex align-items-center volume-check">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="hasVolume" id="hasVolumeCheck"
                    @change="setVolume(hasVolume)">
                  <label class="form-check-label" for="hasVolumeCheck">音声指示</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--テキスト/静止画-->
        <div class="col d-flex" v-bind:class="{
          'flex-column-reverse': isShrink,
          'flex-column': !isShrink,
          'ImageContentShrink': isImageContentShrink
        }">
          <!--テキストコンテンツ-->
          <div class="TextContent" v-bind:class="{ ShrinkTextContent: isShrink }">
            <perfect-scrollbar v-on:ps-y-reach-end="scrollEndLog" ref="TextContentScroll">
              <div v-if="!isModal" id="head-top"></div>
              <div v-else id="head-top-modal"></div>

              <div>＜画像等提供：{{ facilityName }}＞</div>
              <div class="row px-2 g-2 w-100">
                <div v-for="info in patientInfoList" :key="info" class="col-auto">
                  <span class="badge bg-info text-dark">{{ info }}</span>
                </div>
              </div>
              <h2>所見・解説</h2>
              <div v-html="htmlDoc" class="TextContent-width" ref="HtmlContent" />
              <PatientDataPFT v-if="pftHeader.length != 0" :pftHeader="pftHeader" :pftBody="pftBody"
                :fvFile="flowvolumeFile" />
            </perfect-scrollbar>
            <ScrollTop :isModal="isModal" :contentId="content" />
          </div>

          <!--グラフ-->
          <div class="d-flex flex-column ImageContent border-top border-black">
            <div v-show="GraphSelect == 'DM' || GraphSelect == 'LA'" class="bg-white d-flex">
              <div class="form-check form-check-inline ms-2">
                <input class="form-check-input" type="checkbox" v-model="showKokyu" id="showKokyuCheck">
                <label class="form-check-label" for="showKokyuCheck">呼吸状態を表示する</label>
              </div>
              <div class="form-check form-check-inline ms-2">
                <input class="form-check-input" type="checkbox" v-model="showKokyuVideoGraph"
                  id="showKokyuVideoGraphCheck" @change="onShowKokyuVideoGraphCheck()">
                <label class="form-check-label" for="showKokyuVideoGraphCheck">X線動画像上に表示</label>
              </div>
              <button type="button" class="ms-auto me-2 IconButton" @click="changeImageContentShrink()">
                <img v-show="isImageContentShrink" src="@/assets/arrow-up.svg" alt="up">
                <img v-show="!isImageContentShrink" src="@/assets/arrow-down.svg" alt="down">
              </button>
            </div>
            <div class="flex-grow-1 overflow-hidden ShrinkNone" :class="{ 'on-cursor': isChartHover }">
              <Line v-if="GraphSelect == 'DM'" class="bg-white h-100" :data="graphDMData" :options="graphDMOptions" />
              <Line v-if="GraphSelect == 'LA'" class="bg-white h-100" :data="graphLAData" :options="graphLAOptions" />
              <Radar v-if="GraphSelect == 'RC'" class="bg-white h-100" :data="graphRCData" :options="graphRCOptions" />
              <PatientDataBD v-show="GraphSelect == 'BD'" class="bg-white h-100" :bdData="bdData" :fps="fps" />
            </div>

            <!-- ボタン領域 -->
            <div v-if="!isShrink" class="bg-black">
              <!-- 通常表示時 -->
              <ul class="nav justify-content-center gap-1 m-0">
                <li class="nav-item" v-for="item in graphMapping" :key="item">
                  <button class="btn"
                    :class="{ 'btn-primary': item.value == GraphSelect, 'btn-secondary': item.value != GraphSelect, }"
                    v-on:click="setGraphSelect(item.value)">{{ item.text }}</button>
                </li>
              </ul>
            </div>
            <div v-else class="dropdown text-center bg-black">
              <!-- 縮小表示時 -->
              <button class="btn btn-primary dropdown-toggle GraphDropdown" type="button" id="graphDropdownMenu"
                data-bs-toggle="dropdown" aria-expanded="false">
                {{ dispGraphButtonText }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="graphDropdownMenu">
                <li v-for="item in graphMapping" :key="item">
                  <button class="dropdown-item w-100" v-on:click="setGraphSelect(item.value)">{{ item.text }}</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, onMounted, onBeforeUnmount, ref, nextTick } from "vue";
import { useStore } from "vuex";
import { Chart, CategoryScale, Filler, Legend, LinearScale, LineElement, PointElement, RadialLinearScale, Title, Tooltip, } from "chart.js";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { Line, Radar } from "vue-chartjs";
import ContentHelper from "@/helpers/ContentHelper";
import ContentDefine from "@/helpers/ContentDefine";
import LogHelper from "@/helpers/LogHelper";
import axios from "@/helpers/AxiosHelper"
import { apiFileexistsGet } from "@/helpers/ApiHelper"
import ScrollTop from "@/components/Content/ScrollTop.vue";
import PatientDataPFT from "@/components/Content/PatientDataPFT.vue";
import PatientDataBD from "@/components/Content/PatientDataBD.vue";
import VideoPlayer from "@/components/Utility/VideoPlayer.vue";

Chart.register(
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
  AnnotationPlugin,
);

/**
 * @typedef {import("@/helpers/type").ContentData} ContentData コンテンツデータ
 */

export default defineComponent({
  name: "PatientDataViewer",

  //*****************************
  // 使用コンポーネント定義
  //*****************************
  components: {
    ScrollTop,
    Line,
    Radar,
    PatientDataPFT,
    PatientDataBD,
    VideoPlayer,
  },

  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    content: String,
    searchKey: String,
    isModal: Boolean,
  },

  //*****************************
  // 初期化処理
  //*****************************
  setup() {
    const store = useStore();
    const isShrinkMethod = function () {
      return window.innerWidth < ContentDefine.patientDataWidth;
    };

    /**
     * 横幅検知処理
     * @type {import("vue").Ref<boolean>}
     */
    const isShrink = ref(isShrinkMethod());

    // イベント登録解除
    const setShrink = () => {
      isShrink.value = isShrinkMethod();
    };
    onMounted(() => {
      window.addEventListener("resize", setShrink);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", setShrink);
    });

    // テキストコンテンツ用のリサイズ検知
    /** @type {import("vue").Ref<HTMLElement>} */
    const HtmlContent = ref();
    /** @type {import("vue").Ref<import("perfect-scrollbar").default>} */
    const TextContentScroll = ref();
    /** @type {ResizeObserver} */
    let contentObserver;
    onMounted(() => {
      contentObserver = new ResizeObserver(() => {
        TextContentScroll.value?.update();
      });
      contentObserver.observe(HtmlContent.value);
    });
    onBeforeUnmount(() => {
      contentObserver?.disconnect();
    });

    // グラフラベル・データ
    const graphData = ref({
      DM: {
        labels: [],
        datasets: [[], []],
      },
      LA: {
        labels: [],
        datasets: [[], []],
      },
      RC: {
        labels: [],
        datasets: [[], []],
      },
    });
    // グラフボタン
    const graphMapping = ref([
      { text: "横隔膜変位", value: "DM" },
      { text: "肺野面積", value: "LA" },
      { text: "気管径", value: "BD" },
      { text: "レーダーチャート", value: "RC" },
    ]);

    const showKokyuVideoGraph = ref(store.state.UserConfig?.["showKokyuVideoGraph"] ?? false);
    const isImageContentShrink = ref(store.state.UserConfig?.["shrinkKokyuGraph"] ?? false);

    return {
      isShrink,
      graphData,
      graphMapping,
      HtmlContent,
      TextContentScroll,
      showKokyuVideoGraph,
      isImageContentShrink,
    };
  },

  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      /** @type {"Original"|"FE-Mode"|"DM-Mode"|"PL-Mode"|"PH-Mode"|"PH2,LM-Mode"} */
      Select: "Original",
      /** @type {"DM"|"LA"|"RC"|"BD"} */
      GraphSelect: "DM",
      htmlContent: "",
      /** @type {ContentData} */
      dispContent: { name: "" },
      imgDisp: true,
      /**
       * @type {{
       *   "Original": boolean | undefined;
       *   "FE-Mode": boolean | undefined;
       *   "DM-Mode": boolean | undefined;
       *   "PL-Mode": boolean | undefined;
       *   "PH-Mode": boolean | undefined;
       *   "PH2,LM-Mode": boolean | undefined;
       * }}
       */
      buttonState: {},
      /** @type {string[]} */
      patientInfoList: [],
      scrollEndFlag: false,
      fps: 15,
      pitch: 0.388,
      currentTime: 0,
      /** @type {BDData} */
      bdData: {},
      /** 呼吸状態グラフ カーソルホバー */
      isChartHover: false,
      /** 呼吸状態グラフ カーソルクリック */
      isMouseDown: false,
      /** 呼吸状態グラフ表示 */
      showKokyu: true,
      /** 音量有無 */
      hasVolume: false,
      /** VideoJSマウント状態 */
      isVideoMounted: false,

      /** @type {string[]} */
      pftHeader: [],
      /** @type {number[][]} */
      pftBody: [],
      /** @type {string} */
      facilityName: "",
      /** @type {string} */
      flowvolumeFile: "",

      videojs: Object.freeze({ instance: new ContentHelper.VideoJs() }),
    };
  },

  //*****************************
  // 算出プロパティ設定
  //*****************************
  computed: {
    // html表示項目
    htmlDoc() {
      let doc = this.htmlContent;

      if (this.videojs.instance) this.videojs.instance.reset();
      doc = ContentHelper.searchHighlight(doc, this.searchKey);
      nextTick(() => {
        LogHelper.setOnclickEvent(
          this.content,
          this.$refs["HtmlContent"],
          this.selectMethod,
          this.selectModalMethod
        );

        this.videojs.instance.set(this.$refs["HtmlContent"]);
      });
      return doc;
    },
    // 表示映像
    dispMovie() {
      const content = this.serachContent(
        this.$store.state.ContentTree,
        this.content
      );

      // console.log("dispMovie:" + this.Select);
      return encodeURI(
        this.replaceResource(
          `${content.url}/Data_${content.patientId}_001_M_${this.Select}.mp4`
        )
      );
    },
    // ボタン状態
    dispButtonState() {
      return this.buttonState;
    },
    // グラフボタン選択名称
    dispGraphButtonText() {
      const elem = this.graphMapping.find((element) => {
        return this.GraphSelect == element.value;
      });
      return elem?.text ?? "";
    },

    // 横隔膜変位グラフデータ
    graphDMData() { return this.configGraphDMData(); },
    // 動画の横隔膜変位グラフデータ
    graphVideoDMData() { return this.configVideoGraphDMData(); },
    // 横隔膜変位グラフオプション
    graphDMOptions() { return this.configGraphDMOptions(); },
    // 動画の横隔膜変位グラフオプション
    graphVideoDMOptions() { return this.configVideoGraphDMOptions(); },

    // 肺野面積変化グラフデータ
    graphLAData() { return this.configGraphLAData(); },
    // 動画の肺野面積変化グラフデータ
    graphVideoLAData() { return this.configVideoGraphLAData(); },
    // 肺野面積変化グラフオプション
    graphLAOptions() { return this.configGraphLAOptions(); },
    // 動画の肺野面積変化グラフオプション
    graphVideoLAOptions() { return this.configVideoGraphLAOptions(); },

    // COPDグラフデータ
    graphRCData() { return this.configGraphRCData(); },
    // COPDグラフオプション
    graphRCOptions() { return this.configGraphRCOptions(); },
  },
  //*****************************
  // プロパティ監視処理
  //*****************************
  watch: {
    // 選択コンテンツ
    content: {
      handler: function () {
        this.loadContent();
      },
    },
  },
  //*****************************
  // 表示後の実行処理
  //*****************************
  mounted() {
    this.loadContent();

    nextTick(() => {
      // 再生時間取得タイマー設定
      /** @type {InstanceType<VideoPlayer>} */
      const video = this.$refs["VideoPlayerDom"];
      video.player.instance.setInterval(() => {
        this.currentTime = video.player.instance.currentTime();
        this.hasVolume = !video.player.instance.muted();
      }, 1 / 30);

      // コマ送りボタン追加
      /** @type {HTMLElement} */
      const elem = video.player.instance.controlBar.el_;
      const volumeElem = elem.querySelector("div.vjs-volume-panel");

      const prevButton = document.createElement("button");
      prevButton.setAttribute("type", "button");
      prevButton.setAttribute("title", "PrevFrame");
      prevButton.className = "vjs-control vjs-button";
      prevButton.innerHTML = `<img src=${require("@/assets/arrow-prev.svg")} style="height: 40%;"></img>`;
      prevButton.onclick = this.movePrevFrame;

      const nextButton = document.createElement("button");
      nextButton.setAttribute("type", "button");
      nextButton.setAttribute("title", "NextFrame");
      nextButton.className = "vjs-control vjs-button";
      nextButton.innerHTML = `<img src=${require("@/assets/arrow-next.svg")} style="height: 40%;"></img>`;
      nextButton.onclick = this.moveNextFrame;

      volumeElem.before(prevButton);
      volumeElem.before(nextButton);

      this.isVideoMounted = true;
    })

  },

  //*****************************
  // 表示終了後の実行処理
  //*****************************
  beforeUnmount() {
    this.videojs.instance.reset();
  },

  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * 動画コンテンツの設定
     * @param {string} value 対応コンテンツ
     */
    setSelect(value) {
      LogHelper.postLog(
        LogHelper.log.select,
        value,
        this.content,
        this.content
      );
      this.Select = value;
    },
    /**
     * グラフコンテンツの設定
     * @param {string} value 対応コンテンツ
     */
    setGraphSelect(value) {
      const elem = this.graphMapping.find((element) => {
        return value == element.value;
      });

      LogHelper.postLog(
        LogHelper.log.select,
        elem?.text ?? "",
        this.content,
        this.content
      );
      this.GraphSelect = value;
    },
    /**
     * コンテンツボタンの強調表示
     * @param {string} value 対応コンテンツ
     * @returns {boolean} 対応コンテンツと選択中コンテンツが同一であればtrue
     */
    isActive(value) {
      return this.Select == value;
    },
    /**
     * コンテンツ読み込み
     */
    async loadContent() {
      this.dispContent = this.serachContent(
        this.$store.state.ContentTree,
        this.content
      );

      // コンテンツ選択時は先頭の映像を表示する
      if (0 < this.dispContent?.datas?.length) {
        this.Select = this.dispContent.datas[0];
      }
      // テキストコンテンツを取得し、表示に反映する
      this.videojs.instance.reset();
      this.htmlContent = "";
      this.scrollEndFlag = false;
      const getUrl = `${this.dispContent.url}/${this.dispContent.text}`;
      ContentHelper.getTextContent(getUrl).then((res) => {
        // 取得中に表示コンテンツを切り替えていないか確認する
        const nowUrl = `${this.dispContent.url}/${this.dispContent.text}`;
        if (getUrl == nowUrl) {
          this.htmlContent = res.data;
        }
      });

      // 患者情報データ読み込み
      this.loadPatientInfo(this.dispContent.url, this.dispContent.patientId);
      // グラフデータ読み込み
      this.loadCSV(this.dispContent.url, this.dispContent.patientId);

      // コントロールボタンの表示有無を反映する
      const existsList = [];
      for (let index = 0; index < this.dispContent.datas.length; index++) {
        const tag = this.dispContent.datas[index];
        const url = this.replaceResource(
          encodeURI(
            `${this.dispContent.url}/Data_${this.dispContent.patientId}_001_M_${tag}.mp4`
          )
        );
        existsList.push({ tag: tag, url: url });
        this.buttonState[tag] = false;
      }

      try {
        const res = await apiFileexistsGet(existsList);

        if (res != null) {
          // 正常応答の場合、ボタン有効化
          for (let index = 0; index < res.length; index++) {
            this.buttonState[res[index].Tag] = res[index].Exists;
          }
        } else {
          // 異常応答の場合、ボタン無効化
          for (const element in this.buttonState) {
            this.buttonState[element] = false;
          }
        }
      } catch (error) {
        // 通信異常の場合、ボタン無効化
        for (const element in this.buttonState) {
          this.buttonState[element] = false;
        }
      }
    },

    /**
     * グラフCSV読み込み
     * @param {string} url コンテンツフォルダのURL
     * @param {string} patientId 患者ID
     */
    loadCSV(url, patientId) {
      // DMデータ読込
      this.loadCSVDM(encodeURI(this.replaceResource(`${url}/Data_${patientId}_001_G_DM.csv`)));

      // LAデータ読込
      this.loadCSVLA(encodeURI(this.replaceResource(`${url}/Data_${patientId}_001_G_LA.csv`)));

      // RCデータ読込
      this.loadCSVRC(encodeURI(this.replaceResource(`${url}/Data_${patientId}_001_G_RC.csv`)));

      // BDデータ読込
      this.loadCSVBD(encodeURI(this.replaceResource(`${url}/Data_${patientId}_001_G_BD.csv`)));

      // PFTデータ読込
      this.loadCSVPFT(encodeURI(this.replaceResource(`${url}/Data_${patientId}_001_G_PFT.csv`)));
    },

    /**
     * DM CSV読み込み
     * @param {string} url CSVファイルのURL
     */
    loadCSVDM(url) {
      /** 空データ */
      const emptyData = {
        labels: [],
        datasets: [[], []],
      };
      axios
        .get(url)
        .then((res) => {
          if (res.data != null) {
            const config = this.getDMCSVConfig(res.data);
            this.fps = config.fps;
            this.pitch = config.pitch;

            const data = this.parseCSV(res.data, 9, [6, 8]);
            this.graphData.DM = this.convertDMCsv(data, this.fps, this.pitch);
          } else {
            this.graphData.DM = emptyData;
          }
        })
        .catch((error) => {
          console.error(error);
          this.graphData.DM = emptyData;
        });
    },
    /**
     * LA CSV読み込み
     * @param {string} url CSVファイルのURL
     */
    loadCSVLA(url) {
      /** 空データ */
      const emptyData = {
        labels: [],
        datasets: [[], []],
      };
      axios
        .get(url)
        .then((res) => {
          if (res.data != null) {
            const data = this.parseCSV(res.data, 7, [1, 2]);
            this.graphData.LA = this.convertLACsv(data, this.fps);
          } else {
            this.graphData.LA = emptyData;
          }
        })
        .catch((error) => {
          console.error(error);
          this.graphData.LA = emptyData;
        });
    },
    /**
     * RC CSV読み込み
     * @param {string} url CSVファイルのURL
     */
    loadCSVRC(url) {
      /** 空データ */
      const emptyData = {
        labels: [],
        datasets: [[], []],
      };
      axios
        .get(url)
        .then((res) => {
          if (res.data != null) {
            const rc = this.parseCSV(res.data, 1, [3, 2]); // [3,2]の2番目はdon't care
            for (let index = 0; index < rc.datasets[1].length; index++) {
              rc.datasets[1][index] = 100;
            }
            this.graphData.RC = rc;
          } else {
            this.graphData.RC = emptyData;
          }
        })
        .catch((error) => {
          console.error(error);
          this.graphData.RC = emptyData;
        });
    },
    /**
     * BD CSV読み込み
     * @param {string} url CSVファイルのURL
     */
    loadCSVBD(url) {
      axios
        .get(url)
        .then((res) => {
          if (res.data != null) {
            /** @type {string[]} */
            const record = res.data.split("\r\n");
            const datas = record[2].split(",");

            this.bdData.startFrame = parseFloat(datas[0]);
            this.bdData.startBD = parseFloat(datas[1]);
            this.bdData.endFrame = parseFloat(datas[2]);
            this.bdData.endBD = parseFloat(datas[3]);
            this.bdData.stenosisAmount = parseFloat(datas[4]);
            this.bdData.stenosisPercent = parseFloat(datas[5]);
          } else {
            this.bdData = {};
          }
        })
        .catch((error) => {
          console.error(error);
          this.bdData = {};
        });
    },
    /**
     * PFT CSV読み込み
     * @param {string} url CSVファイルのURL
     */
    loadCSVPFT(url) {
      axios
        .get(url)
        .then((res) => {
          if (res.data != null) {
            /** @type {string[]} */
            const record = res.data.split("\n");
            const header = record[0]?.split(",") ?? [];
            const body = [];

            for (let row = 1; row < record.length - 1; row++) {
              const data = record[1]?.split(",") ?? [];
              body.push(data.map((str) => parseFloat(str)));
            }

            this.pftHeader = header;
            this.pftBody = body;
          } else {
            this.pftHeader = [];
            this.pftBody = [];
          }
        })
        .catch((error) => {
          console.error(error);
          this.pftHeader = [];
          this.pftBody = [];
        });
    },

    /**
     * グラフCSV設定読み込み
     * @param {string} csv DMcsvテキスト
     * @returns 撮影条件
     */
    getDMCSVConfig(csv) {
      const fpsStr = csv.match(/FPS,(\d*)/i)[1];
      const pitchStr = csv.match(/SamplingPitch,([.\d]*)/i)[1];
      return { fps: parseInt(fpsStr), pitch: parseFloat(pitchStr) };
    },
    /**
     * グラフCSV読み込み
     * @param {string} csv csvテキスト
     * @param {number} offset 読取開始行数
     * @param {number[]} datapos 患者ID
     * @returns グラフデータ
     */
    parseCSV(csv, offset, datapos) {
      /** @type {string[]} */
      const labels = [];
      /** @type {number[][]} */
      const datasets = [[], []];
      /** @type {string[]} */
      const record = csv.split("\n");
      for (let index = offset; index < record.length; index++) {
        if (record[index] != "") {
          const values = record[index].split(",");
          labels.push(values[0]?.trim());
          datasets[0].push(values[datapos[0]]?.trim());
          datasets[1].push(values[datapos[1]]?.trim());
        }
      }
      return { labels, datasets };
    },

    /**
     * DMデータ編集
     * @param {{labels:string[];datasets:string[][];}} data DMデータ
     * @param {number} fps fps
     * @param {number} pitch SamplingPitch
     * @returns 加工データ
     */
    convertDMCsv(data, fps, pitch) {
      /** @type {string[]} */
      const labels = [];
      for (const frame of data.labels) {
        // フレームから秒数に変換
        const sec = (parseInt(frame) - 1) / fps;
        labels.push(sec.toFixed(2));
      }

      /** @type {function(number[]): number[]} */
      const createDiff = (list) => {
        const min = Math.min(...list);

        /** @type {number[]} */
        const convData = [];
        for (const value of list) {
          // 変位に変換
          convData.push((value - min) * pitch);
        }
        return convData;
      };

      /** @type {number[][]} */
      const datasets = [];
      for (const list of data.datasets) {
        datasets.push(createDiff(list));
      }

      return { labels, datasets };
    },

    /**
     * LAデータ編集
     * @param {{labels:string[];datasets:string[][];}} data LAデータ
     * @param {number} fps fps
     * @returns 加工データ
     */
    convertLACsv(data, fps) {
      /** @type {string[]} */
      const labels = [];
      for (const frame of data.labels) {
        // フレームから秒数に変換
        const sec = (parseInt(frame) - 1) / fps;
        labels.push(sec.toFixed(2));
      }

      /** @type {number[][]} */
      const datasets = [];
      for (const list of data.datasets) {
        datasets.push(list);
      }

      return { labels, datasets };
    },

    /**
     * 患者情報読み込み
     * @param {string} url コンテンツフォルダのURL
     * @param {string} patientId 患者ID
     */
    loadPatientInfo(url, patientId) {
      axios
        .get(encodeURI(`${url}/Data_${patientId}.json`))
        .then((res) => {
          if (res.data != null) {
            this.patientInfoList = [];

            /**
             * @type {{
             *   patientId: string;
             *   age: number;
             *   gender: "M" | "F";
             *   BMI: number;
             *   height: number;
             *   facility: string;
             *   disease: string[];
             * }}
             */
            const info = res.data;
            if (info["age"] != null) {
              this.patientInfoList.push(`${info["age"]}歳`);
            }
            if (info["gender"] != null) {
              this.patientInfoList.push(
                ContentHelper.replaceGender(info["gender"])
              );
            }
            if (info["height"] != null) {
              this.patientInfoList.push(`身長:${info["height"]}`);
            }
            if (info["BMI"] != null) {
              if (info["BMI"] > 0.0) {
                this.patientInfoList.push(`BMI:${info["BMI"]}`);
              }
              else {
                this.patientInfoList.push("BMI:-");
              }
            }
            if (info["disease"] != null) {
              for (let index = 0; index < info["disease"].length; index++) {
                this.patientInfoList.push(info["disease"][index]);
              }
            }
            this.facilityName = info["facility"];
          } else {
            this.patientInfoList = [];
          }
          this.flowvolumeFile = encodeURI(
            `${url}/Data_${patientId}_FlowVolume.png`
          );
        })
        .catch((error) => {
          console.error(error);
          this.patientInfoList = [];
        });
    },

    /**
     * グラフでの同課時間変更
     * @param {import("chart.js").ChartEvent} event 
     * @param {import("chart.js").ActiveElement[]} _elements 
     * @param {import("chart.js").Chart} chart 
     */
    chartMoveCurrentTime(event, _elements, chart) {
      /** @type {InstanceType<VideoPlayer>} */
      const video = this.$refs["VideoPlayerDom"];
      // 総再生時間
      const xMaxSec = this.graphData.DM.labels.length / this.fps;

      const isHover = () => {
        if (!video.player.instance.paused()) {
          // 動画停止中以外は変更不可
          return false;
        }

        // グラフ領域内の判定
        if (chart.chartArea.left <= event.x && event.x <= chart.chartArea.right
          && chart.chartArea.top <= event.y && event.y <= chart.chartArea.bottom) {
          const xLine = chart.chartArea.left + ((this.currentTime / xMaxSec) * chart.chartArea.width);
          if (Math.abs(event.x - xLine) <= 10) {
            // -10～10px以内であればホバー判定
            return true;
          }
        }
        return false;
      };

      const hover = isHover();
      this.isChartHover = hover;

      if (hover && (event.type == "mousedown" || event.type == "touchstart")) {
        // 線上でマウス押下した場合、判定開始
        this.isMouseDown = true;
      }
      if (event.type == "mouseup" || event.type == "touchend") {
        // マウス離上した場合、判定終了
        this.isMouseDown = false;
      }
      if (this.isMouseDown && (event.type == "mousemove" || event.type == "touchmove")) {
        // マウス押下中にマウス移動した場合、再生時間変更

        // マウス位置に対応する再生時間
        const time = (event.x - chart.chartArea.left) * xMaxSec / chart.chartArea.width;
        video.player.instance.currentTime(time);
      }
    },

    /**
     * 横隔膜変位グラフデータテンプレート
     * @returns {import("chart.js").ChartData<"line">}
     */
    configGraphDMDataTemplate() {
      return {
        labels: this.graphData.DM.labels,
        datasets: [
          {
            label: "右",
            data: this.graphData.DM.datasets[0],
            borderColor: "blue",
            fill: false,
          },
          {
            label: "左",
            data: this.graphData.DM.datasets[1],
            borderColor: "red",
            fill: false,
          },
        ],
      };
    },
    /** 横隔膜変位グラフデータ */
    configGraphDMData() { return this.configGraphDMDataTemplate(); },
    /** 動画の横隔膜変位グラフデータ */
    configVideoGraphDMData() {
      const data = this.configGraphDMDataTemplate();
      data.datasets[0].borderColor = "rgba(0, 0, 255, 0.3)"
      data.datasets[1].borderColor = "rgba(255, 0, 0, 0.3)"
      return data;
    },
    /** 横隔膜変位グラフオプションテンプレート */
    configGraphDMOptionsTemplate() {
      const stepSize = this.fps * 2;
      this.setLineChartLabelMax(this.graphData.DM.labels, stepSize);
      const maxFrame = this.graphData.DM.labels.length;
      const maxTicksLimit = Math.floor(maxFrame / stepSize);
      const vue = this;

      /** @type {import("chart.js").ChartOptions<"line">} */
      const option = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        radius: 0,
        events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "mousedown", "mouseup", "touchend"],
        onHover(event, elements, chart) { vue.chartMoveCurrentTime(event, elements, chart); },
        plugins: {
          legend: {
            position: "right",
            onClick: function () {
              return false;
            },
          },
          title: {
            display: true,
            text: "横隔膜の変位グラフ",
          },
          // グラフ注釈
          annotation: vue.getKokyuAnnotation(12),
        },
        scales: {
          x: {
            title: {
              text: "Time[s]",
              display: true,
            },
            ticks: {
              callback: function (val) {
                const time = parseFloat(this.getLabelForValue(val)) * 10;
                if (parseInt(time) % 20 == 0) {
                  return parseInt(this.getLabelForValue(val));
                } else {
                  return "";
                }
              },
              stepSize: stepSize,
              maxTicksLimit: maxTicksLimit,
            },
          },
          y: {
            title: {
              text: "変位量[mm]",
              display: true,
            },
            max: 80,
            // Scaleを固定するときはここに記述
            // suggestedMin: 1000,
            // suggestedMax: 2000,
            // beginAtZero: true,
          },
        },
      };
      return option;
    },
    /** 横隔膜変位グラフオプション */
    configGraphDMOptions() { return this.configGraphDMOptionsTemplate(); },
    /** 動画の横隔膜変位グラフオプション */
    configVideoGraphDMOptions() {
      const option = this.configGraphDMOptionsTemplate();
      option.plugins.legend = { display: false };
      option.plugins.title = { display: false };
      option.scales.x.title = { display: false };
      option.scales.y.title = { display: false };
      return option;
    },

    /** 肺野面積変化グラフデータテンプレート */
    configGraphLADataTemplate() {
      /** @type {import("chart.js").ChartData<"line">} */
      const data = {
        labels: this.graphData.LA.labels,
        datasets: [
          // datasetsが1,0の順になっているが、これは横隔膜変位グラフと(色,左右)の対応を統一するためなので間違いではない。
          {
            label: "面積(右)",
            data: this.graphData.LA.datasets[1],
            borderColor: "blue",
            fill: false,
          },
          {
            label: "面積(左)",
            data: this.graphData.LA.datasets[0],
            borderColor: "red",
            fill: false,
          },
        ],
      };
      return data;
    },
    /** 肺野面積変化グラフデータ */
    configGraphLAData() { return this.configGraphLADataTemplate(); },
    /** 動画の肺野面積変化グラフデータ */
    configVideoGraphLAData() {
      const data = this.configGraphLADataTemplate();
      data.datasets[0].borderColor = "rgba(0,0,255,0.3)";
      data.datasets[1].borderColor = "rgba(255,0,0,0.3)";
      return data;
    },
    /** 肺野面積変化グラフオプションテンプレート */
    configGraphLAOptionsTemplate() {
      const stepSize = this.fps * 2;
      this.setLineChartLabelMax(this.graphData.LA.labels, stepSize);
      const maxTicksLimit = Math.floor(this.graphData.LA.labels.length / stepSize);
      const vue = this;

      /** @type {import("chart.js").ChartOptions<"bar">} */
      const option = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        radius: 0,
        events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "mousedown", "mouseup", "touchend"],
        onHover(event, elements, chart) { vue.chartMoveCurrentTime(event, elements, chart); },
        plugins: {
          legend: {
            position: "right",
            onClick: function () {
              return false;
            },
          },
          title: {
            display: true,
            text: "肺野面積変化",
          },
          // グラフ注釈
          annotation: vue.getKokyuAnnotation(4500),
        },
        scales: {
          x: {
            title: {
              text: "Time[s]",
              display: true,
            },
            ticks: {
              callback: function (val) {
                const time = parseFloat(this.getLabelForValue(val)) * 10;
                if (parseInt(time) % 20 == 0) {
                  return parseInt(this.getLabelForValue(val));
                } else {
                  return "";
                }
              },
              stepSize: stepSize,
              maxTicksLimit: maxTicksLimit,
            },
          },
          y: {
            title: {
              text: "肺野面積[mm2]",
              display: true,
            },
            beginAtZero: true,
            max: 30000,
          },
        },
      };
      return option;
    },
    /** 肺野面積変化グラフオプション */
    configGraphLAOptions() { return this.configGraphLAOptionsTemplate(); },
    /** 動画の肺野面積変化グラフオプション */
    configVideoGraphLAOptions() {
      const option = this.configGraphLAOptionsTemplate();
      option.plugins.legend = { display: false };
      option.plugins.title = { display: false };
      option.scales.x.title = { display: false };
      option.scales.y.title = { display: false };
      return option;
    },

    /** COPDグラフデータ */
    configGraphRCData() {
      /** @type {import("chart.js").ChartData<"radar">} */
      const data = {
        labels: this.graphData.RC.labels,
        datasets: [
          {
            label: "患者計測値",
            data: this.graphData.RC.datasets[0],
            borderColor: "blue",
            fill: false,
          },
          {
            // 基準線
            label: "スパイロ呼吸機能正常群の平均値",
            data: this.graphData.RC.datasets[1],
            borderColor: "red",
            backgroundColor: "rgba(128, 128, 128, 0.2)",
            // borderWidth: "4px",
            fill: true,
          },
        ],
      };
      return data;
    },
    /** COPDグラフオプション */
    configGraphRCOptions() {
      /** @type {import("chart.js").ChartOptions<"radar">} */
      const option = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        radius: 0,
        plugins: {
          legend: {
            position: "right",
            onClick: function () {
              return false;
            },
          },
          title: {
            display: true,
            text: "レーダーチャート",
          },
        },
        scale: {
          r: {
            min: 0,
            max: 300,
            stepSize: 50,
          },
        },
      };
      return option;
    },

    /**
     * 線グラフのX軸の最大値を設定する
     * @param {string[]} labels
     * @param {number} stepSize
     */
    setLineChartLabelMax(labels, stepSize) {
      const remainder = labels.length % stepSize;
      const adder = (stepSize - remainder) % stepSize;
      const max = labels.length + adder;

      labels.length = max;
    },

    /**
     * 呼吸状態の注釈
     * @param {number} y 表示y座標
     * @returns 
     */
    getKokyuAnnotation(y) {

      /** @type {import("chartjs-plugin-annotation").AnnotationPluginOptions} */
      const options = {
        annotations: {
          time: {
            type: "line",
            xMin: this.currentTime * this.fps,
            xMax: this.currentTime * this.fps,
            borderColor: "rgba(127, 127, 127, 0.5)",
            borderWidth: 2,
          },
        },
      };

      if (!this.showKokyu) {
        // 呼吸状態非表示
        return options;
      }

      /**
       * 区切り線の設定
       * @type {import("chartjs-plugin-annotation").AnnotationOptions} 
       */
      const lineOptions = {
        type: "line",
        borderColor: "gray",
        borderWidth: 2,
        borderDash: [2, 2],
      };
      /**
       * 表示文字の設定
       * @type {import("chartjs-plugin-annotation").AnnotationOptions}
       */
      const strOptions = {
        type: "label",
        font: { size: 10 },
        yValue: y,
      };
      const xMaxSec = this.graphData.DM.labels.length / this.fps;
      const timing = [0, 2, 7, 9, 14, xMaxSec];
      const fps = timing.map((v) => v * this.fps);
      options.annotations.kokyu1 = { ...lineOptions, xMin: fps[0], xMax: fps[0], };
      options.annotations.kokyu2 = { ...lineOptions, xMin: fps[1], xMax: fps[1], };
      options.annotations.kokyu3 = { ...lineOptions, xMin: fps[2], xMax: fps[2], };
      options.annotations.kokyu4 = { ...lineOptions, xMin: fps[3], xMax: fps[3], };
      options.annotations.kokyu5 = { ...lineOptions, xMin: fps[4], xMax: fps[4], };
      options.annotations.kokyu1Str = { ...strOptions, xValue: (fps[0] + (fps[1] - fps[0]) / 2), content: ["止めて", "下さい", "(2秒)"] };
      options.annotations.kokyu2Str = { ...strOptions, xValue: (fps[1] + (fps[2] - fps[1]) / 2), content: ["ゆっくり吐いて", "最後まで", "吐き切って下さい", "(5秒)"] };
      options.annotations.kokyu3Str = { ...strOptions, xValue: (fps[2] + (fps[3] - fps[2]) / 2), content: ["止めて", "下さい", "(2秒)"] };
      options.annotations.kokyu4Str = { ...strOptions, xValue: (fps[3] + (fps[4] - fps[3]) / 2), content: ["ゆっくり吸って", "目一杯", "吸い込んで下さい", "(5秒)"] };
      options.annotations.kokyu5Str = { ...strOptions, xValue: (fps[4] + (fps[5] - fps[4]) / 2), content: ["はい、撮影終了です"] };

      return options;
    },

    /** 1フレーム動画を戻す */
    movePrevFrame() {
      /** @type {InstanceType<VideoPlayer>} */
      const video = this.$refs["VideoPlayerDom"];
      const player = video.player.instance;
      player.pause();
      const time = player.currentTime();
      const frame = Math.ceil(time * this.fps);
      player.currentTime((frame - 1) / this.fps);

      LogHelper.postLog(
        LogHelper.log.select,
        "PrevFrame",
        this.dispMovie,
        player.currentTime().toString()
      );
    },
    /** 1フレーム動画を進める */
    moveNextFrame() {
      /** @type {InstanceType<VideoPlayer>} */
      const video = this.$refs["VideoPlayerDom"];
      const player = video.player.instance;
      player.pause();
      const time = player.currentTime();
      const frame = Math.ceil(time * this.fps);
      player.currentTime((frame + 1) / this.fps);

      LogHelper.postLog(
        LogHelper.log.select,
        "NextFrame",
        this.dispMovie,
        player.currentTime().toString()
      );
    },
    /**
     * ミュート設定を変更する
     * @param {boolean} muted 
     */
    setVolume(muted) {
      /** @type {InstanceType<VideoPlayer>} */
      const video = this.$refs["VideoPlayerDom"];
      const player = video.player.instance;
      player.muted(!muted);
    },

    /**
     * X線動画像上に表示時のグラフの縮小表示を設定する
     */
    onShowKokyuVideoGraphCheck() {
      if (this.showKokyuVideoGraph && !this.isImageContentShrink) {
        this.changeImageContentShrink();
      }
      this.$store.commit("setUserLocalConfigKey", { key: "showKokyuVideoGraph", value: this.showKokyuVideoGraph });
    },

    /**
     * グラフの縮小表示を設定する
     * @param {boolean} shrink 
     */
    changeImageContentShrink() {
      this.isImageContentShrink = !this.isImageContentShrink;
      this.$store.commit("setUserLocalConfigKey", { key: "shrinkKokyuGraph", value: this.isImageContentShrink });
    },

    /** コンテンツIDから対象コンテンツデータを取得する */
    serachContent: ContentHelper.serachContent,
    /** リソースURL変換処理 */
    replaceResource: ContentHelper.replaceResource,

    /** スクロール完了ログ送信処理 */
    scrollEndLog() {
      // コンテンツ切替中はログ送信を行わない
      if (this.htmlDoc != "") {
        // 一度送信した後は再送信しない
        if (!this.scrollEndFlag) {
          LogHelper.postLog(
            LogHelper.log.scroll_end,
            this.dispContent.name,
            this.content,
            this.content
          );
          this.scrollEndFlag = true;
        }
      }
    },
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
/* 通常表示時の縦幅 */
.PatientDataViewer {
  --patient-data-movie-height: var(--ddr-content-height);
  /* perfect-scrollbarにあわせて、少数ピクセルが四捨五入されるようにする */
  --patient-data-text-height: calc(var(--ddr-content-height) * 3 / 5 + 0.5px);
  --patient-data-image-height: calc(var(--ddr-content-height) - var(--patient-data-text-height));
}

.PatientDataViewer>.ps {
  height: var(--ddr-content-height);
}

/* モーダル表示時の縦幅 */
.ModalPatientDataViewer {
  --patient-data-movie-height: calc(var(--ddr-content-height) - 59px);
  /* perfect-scrollbarにあわせて、少数ピクセルが四捨五入されるようにする */
  --patient-data-text-height: calc((var(--ddr-content-height) - 59px) * 3 / 5 + 0.5px);
  --patient-data-image-height: calc((var(--ddr-content-height) - 59px) - var(--patient-data-text-height));
}

.ModalPatientDataViewer>.ps {
  height: calc(var(--ddr-content-height) - 59px);
}

/* コンテンツ領域 */
.MovieContent {
  height: var(--patient-data-movie-height);
}

.ShrinkMovieContent {
  max-height: var(--patient-data-movie-height);
}

/* 縮小表示時に動画高さがはみ出るため、別途高さを指定 */
.ShrinkMovieContent video {
  max-height: calc(var(--patient-data-movie-height) - 54px);
}

.TextContent,
.TextContent>.ps {
  height: var(--patient-data-text-height);
}

.ImageContent {
  height: var(--patient-data-image-height);
}

/* グラフ縮小表示時 */
.ImageContentShrink .ShrinkNone {
  display: none;
}

.ImageContentShrink .TextContent {
  flex-grow: 1;
  overflow: hidden;
}

.ImageContentShrink .TextContent>.ps {
  height: 100%;
}

.ImageContentShrink .ImageContent {
  height: auto;
}

.IconButton {
  width: 1em;
  height: 1em;
  padding: 0px;
  border: 0px;
  border-radius: 0.375em;
  background-color: transparent;
  opacity: 0.5;
}

.IconButton>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* 縮小表示時はスクロールバー分縮める */
.ShrinkTextContent {
  width: calc(100% - 16px);
}

/* コンテンツボタン */
.ContentButton {
  color: black;
  background: #ddffff;
  width: 9em;
  font-weight: bold;
}

.active {
  color: white;
  background: darkblue;
}

.ContentButton:disabled {
  background: gray;
}

.volume-check {
  height: 2rem;
  margin-top: 3px;
  margin-bottom: 3px;
  color: white;
}

/* グラフドロップダウンボタン */
.GraphDropdown {
  width: 9em;
}

/* グラフペインの枠線 */
.border-black {
  /* bootstrapの色の上書きのため!importantを使用 */
  border-color: black !important;
}

/* 動画像上のグラフ */
.kokyu-video-graph {
  bottom: 30px;
  z-index: 1;
  height: 200px;
}

/* 動画再生時間変更カーソル */
.on-cursor {
  cursor: pointer;
}
</style>
