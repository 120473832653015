<!-----------------------------------
  ユーザー登録状況
----------------------------------->

<!-----------------------------------
  テンプレート
----------------------------------->
<template>
  <table class="tanstack-vue-table table table-striped table-sm table-bordered" ref="TanstackTable">
    <thead>
      <tr v-for="headerGroup in vueTable.getHeaderGroups()" :key="headerGroup.id">
        <th v-for="header in headerGroup.headers" :key="header.id" :colSpan="header.colSpan"
          :style="{ width: header.getSize() + 'px', cursor: header.column.getCanSort() ? 'pointer' : '' }"
          class="text-break" @click="header.column.getToggleSortingHandler()?.($event)">
          <template v-if="!header.isPlaceholder">
            <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
            <span v-if="header.column.getIsSorted() == 'asc'">
              <img class="sort-icon" src="../../assets/arrow-up.svg" alt="sort" />
            </span>
            <span v-else-if="header.column.getIsSorted() == 'desc'">
              <img class="sort-icon" src="../../assets/arrow-down.svg" alt="sort" />
            </span>
            <div v-else class="h-100 align-middle d-inline-flex flex-column justify-content-center">
              <img class="sort-icon sort-icon-sm" src="../../assets/arrow-up.svg" alt="sort" />
              <img class="sort-icon sort-icon-sm" src="../../assets/arrow-down.svg" alt="sort" />
            </div>
            <div @click.stop="() => { }" @mousedown.stop="header.getResizeHandler()($event)"
              @touchstart.stop="header.getResizeHandler()($event)"
              :class="`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`"
              :style="{ transform: `translateX(${vueTable.getState().columnSizingInfo.deltaOffset} px)` }">
            </div>
          </template>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in vueTable.getRowModel().rows" :key="row.id">
        <td v-for="cell in row.getVisibleCells()" :key="cell.id" class="text-break">
          <slot :cell="cell"></slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<!-----------------------------------
  スクリプト
----------------------------------->
<script lang="js">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import {
  FlexRender,
  getCoreRowModel,
  useVueTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/vue-table";

export default defineComponent({
  name: "TanstackTable",

  components: {
    FlexRender,
  },

  props: {
    itemSource: Array,
    /** @type {{new(): import("@tanstack/vue-table").ColumnDef<any,any>[]}} */
    columns: Array,
    /** @type {{new(): import("@tanstack/vue-table").TableOptions<any>}} */
    options: Object,
    tag: String,
  },

  /** 初期設定 */
  setup(props) {
    // ソート定義
    /** @type {import("@tanstack/vue-table").SortingState} */
    const sorting = ref([])

    /** @type {import("@tanstack/vue-table").TableOptions<any>} */
    const options = props.options ?? {};

    // テーブルのデフォルト設定
    if (options.getCoreRowModel == null) { options.getCoreRowModel = getCoreRowModel(); }

    if (options.state == null) { options.state = { get sorting() { return sorting.value; } }; }
    if (options.state.sorting == null) { Object.defineProperty(options.state, "sorting", { get: function () { return sorting.value; } }); }
    if (options.onSortingChange == null) { options.onSortingChange = (updaterOrValue) => { sorting.value = (typeof updaterOrValue === "function" ? updaterOrValue(sorting.value) : updaterOrValue) }; }
    if (options.getSortedRowModel == null) { options.getSortedRowModel = getSortedRowModel(); }
    if (options.enableSortingRemoval == null) { options.enableSortingRemoval = false; }

    if (options.getFilteredRowModel == null) { options.getFilteredRowModel = getFilteredRowModel(); }

    if (options.columnResizeMode == null) { options.columnResizeMode = "onChange"; }
    if (options.enableColumnResizing == null) { options.enableColumnResizing = true; }

    if (options.data == null) { Object.defineProperty(options, "data", { get: function () { return props.itemSource; } }); }
    if (options.columns == null) { Object.defineProperty(options, "columns", { get: function () { return props.columns; } }); }

    const TanstackTable = ref();
    if (props.tag != null) {
      // 幅の保存／復元を行う
      /** @type {[key:string]:number} */
      const config = JSON.parse(sessionStorage.getItem(`Table-${props.tag}`)) ?? {};
      for (let index = 0; index < options.columns.length; index++) {
        const id = options.columns[index].accessorKey;
        if (config[id] != null) {
          options.columns[index].size = config[id];
        }
      }

      /** @type {ResizeObserver} */
      let tableResizeObserver;
      onMounted(() => {
        tableResizeObserver = new ResizeObserver(() => {
          /** @type {[key:string]:number} */
          const headerSize = {};
          for (const headerGroup of vueTable.getHeaderGroups()) {
            for (const header of headerGroup.headers) {
              headerSize[header.id] = header.getSize();
            }
          }
          sessionStorage.setItem(`Table-${props.tag}`, JSON.stringify(headerSize));
        });
        tableResizeObserver.observe(TanstackTable.value);
      });
      onBeforeUnmount(() => {
        tableResizeObserver?.disconnect();
      });
    }

    const vueTable = useVueTable(options);

    return { vueTable, TanstackTable };
  },
  data() { return {}; }
});
</script>

<!-----------------------------------
  スタイル
----------------------------------->
<style scoped>
table.tanstack-vue-table {
  width: max-content;
  max-width: none;
}

table.tanstack-vue-table thead tr {
  border-top: none;
}

table.tanstack-vue-table th {
  background: #fcfcfc;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.tanstack-vue-table .resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.mobile .tanstack-vue-table .resizer {
  width: 10px;
}

.tanstack-vue-table .resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .tanstack-vue-table .resizer {
    opacity: 0;
  }

  .tanstack-vue-table *:hover>.resizer {
    opacity: 1;
  }
}

.tanstack-vue-table .resizer.isResizing {
  background: blue;
  opacity: 1;
}

.tanstack-vue-table .sort-icon {
  width: 1em;
  margin-top: 1px;
  margin-bottom: 1px;
}

.tanstack-vue-table .sort-icon.sort-icon-sm {
  width: 0.6em;
}
</style>
