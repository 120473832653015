
<!-----------------------------------
    コンテンツビューア
    映像画像テキストのコンテンツ表示の実施を行う
----------------------------------->

<!-----------------------------------
    テンプレート
----------------------------------->
<template>
  <perfect-scrollbar ref="ContentScroll">
    <!--患者リスト-->
    <div class="container-fluid">
      <div class="d-flex flex-column">
        <!--フィルタペイン-->
        <div class="filter-pane mb-3" v-if="showFilterDisplay">
          <button type="button" class="btn btn-primary my-2" @click="changeSearchCheckList(null, null, true)">
            全てチェック
          </button>
          <button type="button" class="btn btn-primary ms-3 my-2" @click="changeSearchCheckList(null, null, false)">
            全て解除
          </button>
          <form id="collapsePatientList" class="border p-1">
            <!--フィルタ種類-->
            <fieldset class="row mb-1" v-for="(filter, parentIndex) in checkItem" :key="filter">
              <legend class="col-form-label pt-0 pb-0" v-bind:class="{ 'col-sm-2': !IsMobile, 'col-auto': IsMobile }">
                <div class="form-check form-check-inline parent-checkbox">
                  <input v-bind:id="filter.tag" type="checkbox" class="form-check-input" v-bind:value="filter.tag"
                    v-bind:indeterminate="filter.indeterminate" v-model="filter.checked"
                    @change="changeSearchCheckList(parentIndex, null, filter.checked)" />
                  <label v-bind:for="filter.tag" class="form-check-label">{{ filter.title }}</label>
                </div>
              </legend>
              <button v-if="IsMobile" class="col-auto btn btn-primary btn-sm" type="button" data-bs-toggle="collapse"
                v-bind:data-bs-target="`#collapsePatientList-${parentIndex}`" aria-expanded="false"
                v-bind:aria-controls="`collapsePatientList-${parentIndex}`">
                詳細
              </button>

              <div v-if="!IsMobile" class="col-sm-10">
                <!-- PC表示時のフィルタ選択項目 -->
                <div class="form-check form-check-inline col-auto" v-for="(item, childIndex) in filter.item" :key="item">
                  <input v-bind:id="`${filter.tag}-${childIndex}`" type="checkbox" class="form-check-input"
                    v-bind:value="item.value" v-model="item.checked"
                    @change="changeSearchCheckList(parentIndex, childIndex, item.checked)" />
                  <label v-bind:for="`${filter.tag}-${childIndex}`" class="form-check-label">{{ item.text }}</label>
                </div>
              </div>
              <div v-else v-bind:id="`collapsePatientList-${parentIndex}`" class="collapse col-12 mt-1"
                data-bs-parent="#collapsePatientList">
                <div class="card card-body" data-v-df0ce1fe="">
                  <div class="row">
                    <!-- モバイル表示時のフィルタ選択項目 -->
                    <div class="form-check form-check-inline col-auto" v-for="(item, childIndex) in filter.item"
                      :key="item">
                      <input v-bind:id="`${filter.tag}-${childIndex}`" type="checkbox" class="form-check-input"
                        v-bind:value="item.value" v-model="item.checked"
                        @change="changeSearchCheckList(parentIndex, childIndex, item.checked)" />
                      <label v-bind:for="`${filter.tag}-${childIndex}`" class="form-check-label">{{ item.text }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>

        <!--サムネイルペイン-->
        <div class="row row-cols-auto g-1">
          <!--患者データ-->
          <div class="col" v-for="patient in patientListItem" :key="patient">
            <div class="card thumbnail">
              <div class="card-body text-center">
                <div class="text-nowrap">
                  {{ patient.age }}歳 {{ patient.gender }}
                </div>
                <div class="text-nowrap">
                  身長:{{ patient.height }} BMI:<span v-if="patient.bmi > 0">{{ patient.bmi }}</span><span v-else>-</span>
                </div>
                <div class="text-nowrap">({{ patient.disease[0] }})</div>
                <img class="border"
                  v-bind:src="replaceResource(`${patient.url}/Data_${patient.patient_id}_Thumbnail.png`)" height="182"
                  alt="Thumbnail" @click.prevent="selectMethod(patient.content_id)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<!-----------------------------------
    スクリプト
----------------------------------->
<script lang="js">
import { defineComponent } from "vue";
import ContentHelper from "@/helpers/ContentHelper";
import { apiSearchPatientGet } from "@/helpers/ApiHelper"

/**
 * @typedef {{
 *   title: string;
 *   tag: "f-gender" | "f-age" | "f-BMI" | "f-disease";
 *   checked: boolean;
 *   indeterminate: boolean;
 *   item: {
 *     text: string;
 *     value: string;
 *     checked: boolean;
 *   }[];
 * }} FilterData
 * @typedef {{
 *   content_id: string;
 *   patient_id: string;
 *   gender: "M" | "F";
 *   age: number;
 *   bmi: number;
 *   height: number;
 *   url: string;
 *   disease: string;
 *   disease_order: number;
 * }} GetPatientData
 * @typedef {{
 *   id: string;
 *   name: string;
 *   type: string;
 *   "filter-display": 0 | 1;
 *   "f-gender": ?number[];
 *   "f-age": ?number[];
 *   "f-BMI": ?number[];
 *   "f-disease": ?string[];
 *   "order": ?string[];
 * }} PatientJson
 */

export default defineComponent({
  name: "PatientListViewer",
  //*****************************
  // 継承プロパティ定義
  //*****************************
  props: {
    listURL: String,
  },
  //*****************************
  // プロパティ定義
  //*****************************
  data() {
    return {
      /** @type {import("@/helpers/type").ConfigJson} */
      configJson: {},
      /** @type {PatientJson} */
      listJson: {},
      /** @type {0 | 1} */
      showFilter: 0,
      /** @type {FilterData[]} */
      filterList: [],
      /** @type {GetPatientData[]} */
      patientList: [],
    };
  },
  //*****************************
  // 算出プロパティ設定
  //*****************************
  computed: {
    // フィルタ表示
    showFilterDisplay() {
      return this.showFilter;
    },
    // 選択項目
    checkItem() {
      return this.filterList;
    },
    // 患者データアイテム
    patientListItem() {
      return this.patientList;
    },
    IsMobile() {
      return this.$store.state.IsMobile;
    },
  },
  //*****************************
  // 初期化処理
  //*****************************
  created() {
    // フィルタデータ
    this.configJson = {};
    this.listJson = {};
    this.showFilter = 0;
    this.filterList = [];

    // 患者データ
    this.patientList = [];

    fetch(encodeURI("./config.json")).then((res) => {
      res.text().then((config) => {
        this.configJson = JSON.parse(config);
        this.fetchListUrl(this.listURL);
      });
    });
  },
  //*****************************
  // プロパティ監視処理
  //*****************************
  watch: {
    // listファイル
    listURL: {
      handler: function () {
        this.fetchListUrl(this.listURL);
      },
    },
    filterList: function () {
      this.scrollReset();
    },
    patientList: function () {
      this.scrollReset();
    },
  },
  //*****************************
  // メソッド定義
  //*****************************
  methods: {
    /**
     * 患者リストJson取得処理
     * @param {string} url jsonファイルのURL
     */
    fetchListUrl(url) {
      fetch(encodeURI(url)).then((res) => {
        res.text().then((list) => {
          try {
            this.listJson = JSON.parse(list);
            this.showFilter = this.listJson["filter-display"];
            this.setFilterList();

            // 表示対象の患者データを取得
            this.fetchPatientList();
          } catch (error) {
            this.showFilter = 0;
            this.patientList = [];
            console.error(error);
          }
        });
      });
    },

    /**
     * 患者リストJson取得処理
     */
    async fetchPatientList() {
      // 検索条件が有る場合は条件を設定する
      // 検索条件がない場合は空条件を設定する
      // フィルタ対象外の場合はnullを設定する

      /**
       * @type {{
       *   "gender": string[];
       *   "age": string[];
       *   "BMI": string[];
       *   "disease": string[];
       * }}
       */
      const condition = {};

      this.filterList.forEach((filter) => {
        const tag = filter.tag.replace("f-", "");
        if (filter.checked || filter.indeterminate) {
          // 全てチェック もしくは 一部の条件が選択されている場合
          condition[tag] = [];
          filter.item.forEach((item) => {
            if (item.checked) {
              // 選択された条件を追加
              condition[tag].push(item.value);
            }
          });
        } else {
          // 全解除の場合は検索条件なし
          condition[tag] = ["null"];
        }
      });
      //console.log(condition);

      // api経由で検索結果を取得する
      const response = await apiSearchPatientGet(condition);

      this.patientList = this.setOrder(response);
      //console.log(response);
    },

    /**
     * 検索結果の並べ替えを行う
     * @param {GetPatientData[]} list 検索結果
     */
    setOrder(list) {
      if (this.listJson.order == null) {
        // 指定順序がない場合はデフォルトのまま
        return list;
      }
      /**
       * 指定順序
       * @type {{[key:string]:number}}
       */
      const order = {};
      for (let index = 0; index < this.listJson.order.length; index++) {
        const id = this.listJson.order[index];
        order[id] = index;
      }

      /**
       * 並べ替えた患者リスト
       * @type {{order:number;index:number;data:GetPatientData;}[]} 
       */
      const sortList = [];
      for (let index = 0; index < list.length; index++) {
        const p = list[index];
        if (order[p.patient_id] != null) {
          sortList.push({ order: order[p.patient_id], index: index, data: p, });
        } else {
          sortList.push({ order: Number.MAX_VALUE, index: index, data: p, });
        }
      }
      sortList.sort((a, b) => {
        if (a.order < b.order) { return -1; }
        else if (b.order < a.order) { return 1; }

        if (a.index < b.index) { return -1; }
        else if (b.index < a.index) { return 1; }

        return 0;
      });

      const newList = [];
      for (const s of sortList) {
        newList.push(s.data);
      }

      return newList;
    },

    /**
     * リストフィルタ項目設定処理
     */
    setFilterList() {
      const defaultChecked = true;

      /** @type {function(FilterData[], "f-gender" | "f-age" | "f-BMI" | "f-disease"): void} */
      const pushFilterIndex = (filterList, name) => {
        if (this.listJson?.[name] != null) {
          /** @type {FilterData} */
          const filter = {
            title: this.configJson["filter"][name]["title"],
            tag: name,
            checked: defaultChecked,
            indeterminate: false,
            item: [],
          };
          const dataList = this.configJson["filter"][name]["item"];
          for (let i = 0; i < this.listJson[name].length; i++) {
            filter["item"].push(dataList[this.listJson[name][i]]);
            filter["item"][i]["checked"] = defaultChecked;
          }
          filterList.push(filter);
        }
      };
      /** @type {function(FilterData[], "f-gender" | "f-age" | "f-BMI" | "f-disease"): void} */
      const pushFilterValue = (filterList, name) => {
        if (this.listJson?.[name] != null) {
          /** @type {FilterData} */
          const filter = {
            title: this.configJson["filter"][name]["title"],
            tag: name,
            checked: defaultChecked,
            item: [],
          };
          for (let i = 0; i < this.listJson[name].length; i++) {
            filter["item"].push({
              text: this.listJson[name][i],
              value: this.listJson[name][i],
              checked: defaultChecked,
            });
          }
          filterList.push(filter);
        }
      };

      this.filterList = [];
      pushFilterIndex(this.filterList, "f-gender");
      pushFilterIndex(this.filterList, "f-age");
      pushFilterIndex(this.filterList, "f-BMI");
      pushFilterValue(this.filterList, "f-disease");
    },

    /**
     * リスト表示フィルタ選択処理
     * @param {number | null} parent グループ名
     * @param {number | null} child 項目名
     * @param {boolean} checked チェック状態
     */
    changeSearchCheckList(parent, child, checked) {
      if (parent == null) {
        // 全選択の場合、全てのチェックを更新する
        for (
          let parentIndex = 0;
          parentIndex < this.filterList.length;
          parentIndex++
        ) {
          // チェック状態を更新
          this.filterList[parentIndex]["checked"] = checked;
          // 未確定の状態をリセット
          this.filterList[parentIndex]["indeterminate"] = false;

          // 子のチェック状態を更新
          if (this.filterList[parentIndex]["item"] != null) {
            for (
              let childIndex = 0;
              childIndex < this.filterList[parentIndex]["item"].length;
              childIndex++
            ) {
              this.filterList[parentIndex]["item"][childIndex]["checked"] =
                checked;
            }
          }
        }
      } else if (child == null) {
        // 親項目がチェックされた場合、全ての子項目のチェックを更新する

        // 未確定の状態をリセット
        this.filterList[parent]["indeterminate"] = false;

        // 親項目がチェックされた場合、子項目を全て親項目に合わせる
        if (this.filterList?.[parent]?.["item"] != null) {
          for (let i = 0; i < this.filterList[parent]["item"].length; i++) {
            this.filterList[parent]["item"][i]["checked"] = checked;
          }
        }
      } else {
        // 子項目がチェックされた場合、親項目の状態を更新する
        if (this.filterList?.[parent]?.["item"] != null) {
          let checkFlag = true;
          let uncheckFlag = false;
          for (let i = 0; i < this.filterList[parent]["item"].length; i++) {
            checkFlag =
              checkFlag && this.filterList[parent]["item"][i]["checked"];
            uncheckFlag =
              uncheckFlag || this.filterList[parent]["item"][i]["checked"];
          }

          this.filterList[parent]["checked"] = checkFlag;
          // 未確定の状態をセット
          this.filterList[parent]["indeterminate"] = !checkFlag && uncheckFlag;
        }
      }

      // 表示対象の患者データを取得
      this.fetchPatientList();
    },

    /**
     * ページ遷移処理
     * @param {string} value 選択コンテンツ
     */
    selectMethod(value) {
      this.$emit("selectMethod", value);
    },

    /**
     * スクロールリセット処理
     */
    scrollReset() {
      /** @type {import("perfect-scrollbar").default} */
      const scroll = this.$refs["ContentScroll"];
      if (scroll?.$el?.scrollTop != null) {
        scroll.$el.scrollTop = 0;
      }
    },

    /** リソースURL変換処理 */
    replaceResource: ContentHelper.replaceResource,
  },
});
</script>

<!-----------------------------------
    スタイル
----------------------------------->
<style scoped>
.ps {
  height: var(--ddr-content-height);
}

.mobile .parent-checkbox {
  width: 8em;
}

.thumbnail {
  border: none;
  font-size: 12px;
}

.thumbnail img {
  cursor: pointer;
}

.thumbnail img:hover {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 25%);
}
</style>
